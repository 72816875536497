// import React, { Component } from 'react';
// import { loginUser } from '../../Actions/authActions';
// import { connect } from 'react-redux';
// import './LoginStyle.css';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import ButtonSpinner from '../../Common/Spinners/ButtonSpinner';
// import { loginTranslations } from '../../Translations/loginTranslations'
// import { StaticContext } from 'react-router';
// import { WithTranslation } from 'react-i18next';
// import { Auth, Errors, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import queryString from 'query-string';
// import isEmpty from '../../Utilities/is_empty';
// import Modal from 'react-modal';
// import { PlaceCartOrderPayload } from '../../Interfaces/PayloadsAndResponses/Cart';
// import { placeCartOrder } from '../../Actions/cartActions';
// // import dayjs from 'dayjs';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     maxWidth: '500px',
//     width: '90vw',
//     padding: '0',
//     background: 'none',
//     border: 'none',
//     borderRadius: '18px'
//   }
// };

// export type LoginUserPayLoad = {
//   email: string;
//   password: string;
// }

// type Loginstate = {
//   email: string;
//   password: string;
//   isChecked: boolean;
//   errors: string;
//   loading: boolean;
//   resetMessage: string;
//   isPasswordShown: boolean;
//   modalIsOpen: boolean;
// }

// type LoginProps = {
//   i18n?: WithTranslation | null;
//   errors?: Errors;
//   auth?: Auth;
//   cartOrderPayloadFromRedux?: PlaceCartOrderPayload;
//   isMarketPlaceLogin?: boolean;
//   isCartLogin?: boolean;
//   cartDisplayHandling?: ((page: any) => void) | undefined;
//   onRef?: (openModalRef: (() => void) | null) => void;
//   signupLoginRedirect?: ((page: any) => void) | undefined;
//   loginUser?: (userData: LoginUserPayLoad, errorCallback: () => void, loginSuccessHandler?: (res: any) => void, cartOrderPayload?: PlaceCartOrderPayload) => void;
//   placeCartOrder?: (orderData: PlaceCartOrderPayload) => void;
//   cartOrderPayload?: PlaceCartOrderPayload;
// } & RouteComponentProps<{}, StaticContext, { resetMessage: string, to: { hash: string, pathname: string, search: string, state: undefined | unknown } }>;

// class Login extends Component<LoginProps, Loginstate> {
//   hasAddress = JSON.parse(localStorage.getItem('address') || '{}');
//   // constructor() {
//   // super();
//   state: Loginstate = {
//     email: '',
//     password: '',
//     isChecked: false,
//     errors: '',
//     loading: false,
//     resetMessage: '',
//     isPasswordShown: false,
//     modalIsOpen: false
//   }
//   // }

//   componentDidMount() {
//     // redirect to pubic marketplace changed behaviour of default login page , added false to stop if condition
//     // let zonedDateTime = new Date(new Date().toLocaleString("en-US", { timeZone: this.hasAddress.timezone }))
//     // let thresholdDateTime = new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00') // date for marketplace with threshold: 2pm
//     // const dateForMarketplace = dayjs().add((dayjs(zonedDateTime).isAfter(new Date(thresholdDateTime))? 2 : 1), 'day').format('M-D-YYYY');
//     // if(this.hasAddress.latitude) {
//     //   this.props.history?.push({ pathname: `/home/${dateForMarketplace}` })
//     // }
//     if (this.props.location.search.split('=')[1] && this.props.location.search.split('=')[1] !== 'billing') {
//       const serachParams = queryString.parse(this.props?.location?.search);
//       const { order_id = undefined, runningmenu_id = undefined } = serachParams || {};
//       order_id && localStorage.setItem('order_id', `${order_id}`);
//       runningmenu_id && localStorage.setItem('runningmenu_id', `${runningmenu_id}`);
//     }
//     // window.history.pushState(null, null, '/')
//     document.title = 'Chowmill'
//     var i = Math.floor((Math.random() * 9) + 1);
//     let bannerElement: HTMLElement | null = document.getElementById('banner');
//     if (bannerElement)
//       bannerElement.style.backgroundImage = 'url(imgs/' + i + '.jpg)';
//     if (this.props.auth?.isAuthenticated && this.props.location.search.split('=')[1] !== 'billing') {
//       // this.props.history.push('/dashboard');
//       //commented this line coz was causing redirects to /menu on every link or redirect //removed comment not required
//       this.props.history?.push({ pathname: `/menu` })
//     } else if (this.props.auth?.isAuthenticated) {
//       this.props.history.push('/profile/billing');
//     }
//   }
//   componentWillMount() {
//     loginTranslations();
//     // when params sent via url
//     if (this.props.history.location.state) {
//       this.setState({ resetMessage: this.props.history.location.state.resetMessage });
//     }
//   }
//   UNSAFE_componentWillReceiveProps(nextProps: LoginProps) {
//     if (nextProps.auth?.isAuthenticated) {
//       // this.redirectUser();
//       if (this.props.isMarketPlaceLogin === undefined && this.props.isCartLogin === undefined) {
//         this.redirectUser();
//       }
//     }
//     if (nextProps.errors) {
//       this.setState({ errors: nextProps.errors.errors });
//     }
//     this.setState({ loading: false });

//     if (nextProps.onRef) {
//       this.props.onRef && this.props.onRef(this.openModal)
//     }
//   }

//   loginSuccessHandler = (res: any) => {
//     if(res === 'fromMarketPlace') {
//       window.location.href = `/menu`;
//     } 
//     //else if(res) {
//       //window.location.href = `/menu/${dayjs(res.delivery_at).format('M-D-YYYY')}/${res?.slug}`
//     // }

//     // if (this.props.isCartLogin && this.props.cartOrderPayload) {
//       // this.props.placeCartOrder?.(this.props?.cartOrderPayload as PlaceCartOrderPayload);
//       // this.props.history.push({ pathname: `/menu/${this.props.cartOrderPayload?.runningmenu.delivery_at}/` });
//       // this.props.history.push({pathname: `/menu/`})
//       // window.location.href = `/menu/${this.props.cartOrderPayload?.runningmenu.delivery_at}`;
//     // } else if(this.props.isMarketPlaceLogin){
//       // window.location.href = `/menu/`
//     // }
//     // window.location.reload()
//     // window.location.href = `/menu/`
//   }

//   redirectUser() {
//     const {
//       history: {
//         location: { state: { to: { pathname = '', search = '' } = {} } = { to: {} } },
//       },
//     } = this.props;
//     if (pathname) {
//       window.location.href = `${pathname}${search ? search : ''}`;
//     } else if (!this.props.isMarketPlaceLogin) {
//         if (this.props.history.location.search.split('=')[1] !== 'billing') {
//           window.location.href = `/menu`;
//         } else {
//           window.location.href = '/profile/billing'
//         }
//     } 
//   }
//   shouldComponentUpdate() {
//     return true;
//   }
//   onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
//     this.setState({ [e.target.name]: value } as Pick<Loginstate, keyof Loginstate>)
//   }
//   handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
//     this.setState({ [e.target.name]: e.target.value } as unknown as Pick<Loginstate, keyof Loginstate>)
//   }

//   onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     this.setState({ loading: true });
//     const userData = {
//       email: this.state.email,
//       password: this.state.password
//     };

//     if (this.props.isMarketPlaceLogin || this.props.isCartLogin){
//       if(this.props?.cartOrderPayloadFromRedux && this.props?.cartOrderPayloadFromRedux.runningmenu?.orders_attributes?.length > 0) {
//         this.props.loginUser?.(userData, () => {this.setState({loading: false})}, undefined, this.props?.cartOrderPayloadFromRedux as PlaceCartOrderPayload)
//       } else {
//         this.props.loginUser?.(userData, () => {this.setState({loading: false})}, this.loginSuccessHandler)
//       }
//     } else {
//       this.props.loginUser?.(userData, () => {this.setState({loading: false})})
//     }

//     // this.props.isMarketPlaceLogin ?
//     //   this.props.loginUser(userData, () => {this.setState({loading: false})}, undefined, this.props?.cartOrderPayloadFromRedux as PlaceCartOrderPayload)
//     //   : this.props.isCartLogin ?
//     //     this.props.loginUser(userData, () => {this.setState({loading: false})}, undefined, this.props?.cartOrderPayload as PlaceCartOrderPayload)
//     //   :
//     //     this.props.loginUser(userData, () => {
//     //       this.setState({
//     //         loading: false,
//     //         })
//     //       }
//     //       //,
//     //       // () => {
//     //       //   this.closeModal()
//     //       // }
//     //       // this.props.cartOrderPayload
//     //       )
//   }
//   togglePasswordVisibility = () => {
//     const { isPasswordShown } = this.state
//     this.setState({ isPasswordShown: !isPasswordShown })
//   }

//   openModal = () => this.setState({ modalIsOpen: true });

//   closeModal = () => this.setState({ modalIsOpen: false });

//   render() {
//     const { isPasswordShown, errors, resetMessage, modalIsOpen } = this.state;
//     const { i18n, isMarketPlaceLogin } = this.props;

//     return (
//       <>
//       {isMarketPlaceLogin ?
//         <Modal
//           isOpen={modalIsOpen}
//           // isOpen={isOpenSignup ?? false}
//           style={customStyles}
//           onRequestClose={this.closeModal}
//         >
//           <div className='modal-dialog'>
//             <div className='modal-content px-4 py-32' style={{ 'borderRadius': '10px' }}>
//               {/* Modal Header */}
//               <div className={`border-0 bg-white d-flex justify-content-between align-items-center`}>
//                 <span></span>
//                 <h4 id='modal-title' className='modal-title fooditem-text-capitalize text-case order-popup-title order-popup-header-title'>
//                   {i18n ? i18n.t('Log In') : 'Log In'}
//                 </h4>
//                 <button type='button' onClick={this.closeModal} className='close m-0 p-0 pr-2 modal-close ' data-dismiss='modal' aria-label='Close'>
//                   <span aria-hidden='true'>
//                     <i className="fas fa-times"></i>
//                   </span>
//                 </button>
//               </div>
//               {/* Modal Body */}
//               <div
//                 // style={{ height: `calc(95vh - 290px)`, overflowX: 'hidden',overflowY: 'scroll' }}
//                 className='position-relative order-poup-header-wrapper '
//                 id='order-popup-body'
//               >
//                 {errors ?
//                   <div className='alert alert-danger login-alert' role='alert' >
//                     {errors}
//                   </div>
//                   : ''
//                 }
//                 <form className='form-signin px-2 w-100' onSubmit={this.onSubmit}>
//                   <label htmlFor='inputEmail' className='mb-1'> { i18n.t('Email address')} </label>
//                   <input type='email' id='inputEmail' className='form-control rounded' name='email' value={this.state.email} onChange={this.handleChange} autoComplete='on' required autoFocus />

//                   <label className='mt-3 mb-1' htmlFor='inputPassword'> { i18n.t('Password')}</label>
//                   <div className='position-relative'>
//                     <input type={(isPasswordShown) ? 'text' : 'password'} id='inputPassword' className='form-control rounded' name='password' value={this.state.password} onChange={this.handleChange} autoComplete='current-password' required />
//                     <i className={`password-icon fas fa-solid ${isPasswordShown ? 'fa-eye-slash' : 'fa-eye'} `}
//                       onClick={this.togglePasswordVisibility}></i>
//                   </div>
//                   <div className='checkbox mb-3 mt-3 d-flex justify-content-between align-items-center'>
//                     <label className='text-gray-primary m-0'>
//                       <input type='checkbox' defaultValue='remember-me' name='isChecked' checked={this.state.isChecked} onChange={this.onChange} />&nbsp; { i18n.t('Keep me logged in')}
//                     </label>
//                     <React.Fragment>
//                       <Link className='text-danger d-block text-center fw-600' to='/forgot_password' > { i18n.t('Forgot Password ?')}</Link>
//                     </React.Fragment>
//                   </div>
//                   <button
//                     type='submit'
//                     className='btn btn-lg btn-danger btn-block'
//                   >
//                     {this.state.loading ?
//                       <ButtonSpinner /> :
//                        i18n.t('Log In')
//                     }
//                   </button>
//                   {/* <p className='mt-5 mb-3 text-muted text-center'>
//                   { (i18n.t('© Copyright') + new Date().getFullYear() + i18n.t('Chowmill'))}
//                 </p> */}
//                   <p className='fs-18 fw-normal text-center text-gray-200 m-0 mt-3'>Not currently registered? <span className='text-danger cursor-pointer' onClick={() => { this.closeModal(); this.props.signupLoginRedirect?.('login') }}>Sign Up</span></p>
//                 </form>

//               </div>
//             </div>
//           </div>
//         </Modal>
//       :
//         <>
//           {!this.props.isCartLogin ?
//             <div className='container-fluid h-100 '>
//               <div className='row h-100 justify-content-center'>
//                 <div className='col-12 col-md-4 text-center'>
//                   <div className='d-flex justify-content-center align-items-center mt-5'>
//                     <img src='/imgs/chowmill_icon.svg' className='mr-3' alt='' style={{ height: 60 }} />
//                     <h1 className='m-0 logo-chowmill-t' style={{ color: '#303030', fontSize: 40 }}>Chowmill</h1>
//                   </div>
//                   <h2 className='mt-5'>{ i18n.t('Login')}</h2>
//                   <h5 className='signup_subtext'>{ i18n.t('Not currently registered?')}&nbsp;
//                     <Link to='/user/signup' >{ i18n.t('Signup')}</Link> </h5>
//                   {errors ?
//                     <div className='alert alert-danger login-alert' role='alert' >
//                       {errors}
//                     </div>
//                     : ''
//                   }
//                   {resetMessage && isEmpty(errors) ?
//                     <div className='alert alert-success login-alert' role='alert'>
//                       { i18n.t('Please login below using your Email and Password.')}
//                     </div>
//                     : ''}
//                   <form className='form-signin' onSubmit={this.onSubmit}>
//                     <label htmlFor='inputEmail'> { i18n.t('Email address')} </label>
//                     <input type='email' id='inputEmail' className='form-control' name='email' value={this.state.email} onChange={this.handleChange} autoComplete='on' required autoFocus />

//                     <label className='mt-4' htmlFor='inputPassword'> { i18n.t('Password')}</label>
//                     <div className='position-relative'>
//                       <input type={(isPasswordShown) ? 'text' : 'password'} id='inputPassword' className='form-control' name='password' value={this.state.password} onChange={this.handleChange} autoComplete='current-password' required />
//                       <i className={`password-icon fas fa-solid ${isPasswordShown ? 'fa-eye-slash' : 'fa-eye'} `}
//                         onClick={this.togglePasswordVisibility}></i>
//                     </div>
//                     <div className='checkbox mb-3 mt-3'>
//                       <label>
//                         <input type='checkbox' defaultValue='remember-me' name='isChecked' checked={this.state.isChecked} onChange={this.onChange} />&nbsp; { i18n.t('Keep me logged in')}
//                       </label>
//                       <React.Fragment>
//                         <Link className='text-danger d-block text-center' to='/forgot_password' > { i18n.t('Forgot Password')}</Link>
//                       </React.Fragment>
//                     </div>
//                     <button
//                       type='submit'
//                       className='btn btn-lg btn-danger btn-block'
//                     >
//                       {this.state.loading ?
//                         <ButtonSpinner /> :
//                          i18n.t('Sign in')
//                       }
//                     </button>
//                     <p className='mt-5 mb-3 text-muted text-center'>
//                       { (i18n.t('© Copyright') + new Date().getFullYear() + i18n.t('Chowmill'))}
//                     </p>
//                   </form>
//                 </div>
//                 <div className='col-md-8 d-none d-lg-block login_banner' id='banner'>
//                 </div>
//               </div>
//             </div>
//             :
//             <div className='text-center'>
//               {errors ?
//                 <div className='alert alert-danger login-alert' role='alert' >
//                   {errors}
//                 </div>
//                 : ''
//               }
//               {resetMessage && isEmpty(errors) ?
//                 <div className='alert alert-success login-alert' role='alert'>
//                   { i18n.t('Please login below using your Email and Password.')}
//                 </div>
//                 : ''}
//               <form className='form-signin' onSubmit={this.onSubmit}>
//                 <label htmlFor='inputEmail'> { i18n.t('Email address')} </label>
//                 <input type='email' id='inputEmail' className='form-control' name='email' value={this.state.email} onChange={this.handleChange} autoComplete='on' required autoFocus />

//                 <label className='mt-4' htmlFor='inputPassword'> { i18n.t('Password')}</label>
//                 <div className='position-relative'>
//                   <input type={(isPasswordShown) ? 'text' : 'password'} id='inputPassword' className='form-control' name='password' value={this.state.password} onChange={this.handleChange} autoComplete='current-password' required />
//                   <i className={`password-icon fas fa-solid ${isPasswordShown ? 'fa-eye-slash' : 'fa-eye'} `}
//                     onClick={this.togglePasswordVisibility}></i>
//                 </div>
//                 <div className='checkbox mb-3 mt-3 d-flex aling-items-center justify-content-between'>
//                   <label>
//                     <input type='checkbox' defaultValue='remember-me' name='isChecked' checked={this.state.isChecked} onChange={this.onChange} />&nbsp; { i18n.t('Keep me logged in')}
//                   </label>
//                   <React.Fragment>
//                     <Link className='text-danger d-block text-center' to='/forgot_password' > { i18n.t('Forgot Password')}</Link>
//                   </React.Fragment>
//                 </div>
//                 <button
//                   type='submit'
//                   className='btn btn-lg btn-danger btn-block'
//                 >
//                   {this.state.loading ?
//                     <ButtonSpinner /> :
//                      i18n.t('Log In')
//                   }
//                 </button>
//                 <p className='fs-18 fw-normal text-center text-gray-200 m-0 mt-3'>Not currently registered? <span className='text-danger cursor-pointer' onClick={() => this.props.cartDisplayHandling?.('signUp')}>Sign Up</span></p>
//               </form>
//             </div>
//           }
//         </>
//       }
//       </>
//     );
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   auth: state.auth,
//   errors: state.errors,
//   i18n: state.i18n.i18n,
//   cartOrderPayloadFromRedux: state.cart?.cart_order_payload
// });
// export default connect(mapStateToProps, { loginUser, placeCartOrder })(Login);

import React, { FC, useEffect, useState } from 'react';
import { loginUser } from '../../Actions/authActions';
import { useSelector, useDispatch } from 'react-redux';
import './LoginStyle.css';
import { Link, useHistory } from 'react-router-dom';
import ButtonSpinner from '../../Common/Spinners/ButtonSpinner';
import { loginTranslations } from '../../Translations/loginTranslations'
import { Auth, Errors, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import queryString from 'query-string';
import isEmpty from '../../Utilities/is_empty';
import Modal from 'react-modal';
import { PlaceCartOrderPayload } from '../../Interfaces/PayloadsAndResponses/Cart';
import { getDynamicTitles } from '../../Actions/marketplaceActions';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import useScreenSize from '../../Utilities/customHooks/useScreenSize';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '468px',
    width: '90vw',
    padding: '0',
    background: 'none',
    border: 'none',
    borderRadius: '18px'
  }
};

export type LoginUserPayLoad = {
  email: string;
  password: string;
}
export type GoogleAuthenticatedUser = {
  google_token: string;
}

type Loginstate = {
  email: string;
  password: string;
  isChecked: boolean;
  errors: string;
  loading: boolean;
  resetMessage: string;
  isPasswordShown: boolean;
  continueWithGoogle: boolean;
  googleIdentificationToken: string;
  googleSignupInProgress: boolean
  // modalIsOpen: boolean;
}

type LoginProps = {
  isMarketPlaceLogin?: boolean;
  isCartLogin?: boolean;
  cartDisplayHandling?: ((page: any) => void) | undefined;
  onRef?: (openModalRef: (() => void) | null) => void;
  signupLoginRedirect?: ((page: any) => void) | undefined;
  setModalState?: (isOpen: boolean) => void;
  closeModal?: () => void;
  isOpenModal: boolean;
}
const Login: FC<LoginProps> = ({
  isMarketPlaceLogin,
  isCartLogin,
  cartDisplayHandling,
  onRef,
  signupLoginRedirect,
  closeModal,
  isOpenModal
}) => {

  const history = useHistory();
  const dispatch: any = useDispatch();

  const auth: Auth = useSelector((state: ReduxRootState) => state.auth);
  const errors: Errors = useSelector((state: ReduxRootState) => state.errors);
  const { i18n } = useSelector((state: ReduxRootState) => state.i18n);
  const cartOrderPayloadFromRedux = useSelector((state: ReduxRootState) => state.cart?.cart_order_payload);
  const { rawWidth, styles } = useScreenSize();

  const [loginState, setLoginState]  = useState<Loginstate>({
    email: '',
    password: '',
    isChecked: false,
    errors: '',
    loading: false,
    resetMessage: '',
    isPasswordShown: false,
    continueWithGoogle: false,
    googleIdentificationToken: '',
    googleSignupInProgress: false
    // modalIsOpen: false
  });

  useEffect(() => {
    //componentWillMount
    loginTranslations();
    // when params sent via url
    if (history.location.state) {
      setLoginState({...loginState, resetMessage: history.location.state.resetMessage});
    }

    const { isCompanyAdmin } = getloggedInUser()
    const queryStringSEO = window.location.search;
    // Create a URLSearchParams object
    const paramsDataData = new URLSearchParams(queryStringSEO);
    const titleValue = paramsDataData.get('k');

    if (!isEmpty(titleValue)) {
      isCompanyAdmin && dispatch(getDynamicTitles(titleValue, () => { }))
    }

    localStorage?.removeItem?.('signup-in-progress');

    //componentDidMount
    if (window.location.search.split('=')[1] && window.location.search.split('=')[1] !== 'billing' && isEmpty(titleValue)) {
      const serachParams = queryString.parse(window?.location?.search);
      const { order_id = undefined, runningmenu_id = undefined } = serachParams || {};
      order_id && localStorage.setItem('order_id', `${order_id}`);
      runningmenu_id && localStorage.setItem('runningmenu_id', `${runningmenu_id}`);
    }
    // window.history.pushState(null, null, '/')
    // document.title = 'Chowmill'
    var i = Math.floor((Math.random() * 9) + 1);
    let bannerElement: HTMLElement | null = document.getElementById('banner');
    if (bannerElement)
      bannerElement.style.backgroundImage = 'url(imgs/' + i + '.jpg)';
    if (auth?.isAuthenticated && window.location.search.split('=')[1] !== 'billing') {
      // this.props.history.push('/dashboard');
      //commented this line coz was causing redirects to /menu on every link or redirect //removed comment not required
      history?.push({ pathname: `/menu` })
    } else if (auth?.isAuthenticated) {
      history.push('/profile/billing');
    }
    // eslint-disable-next-line
  }, [])

  const loginSuccessHandler = (res: any) => {
    if(res === 'fromMarketPlace') {
      window.location.href = `/menu`;
    }
    localStorage?.removeItem?.('address');
  }

  const redirectUser = () => {
    const { pathname, search } = history.location;
    if (pathname) {
      // history?.push('/menu')
      window.location.href = `${pathname}${search ? search : ''}`;
    } else if (!isMarketPlaceLogin) {
      if (search.split('=')[1] !== 'billing') {
        // history?.push('/menu')
        window.location.href = `/menu`;
      } else {
        // history?.push('/profile/billing')
        window.location.href = '/profile/billing'
      }
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setLoginState({...loginState, [e.target.name]: value} as Pick<Loginstate, keyof Loginstate>)
  }
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLoginState({...loginState, [e.target.name]: e.target.value} as unknown as Pick<Loginstate, keyof Loginstate>)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoginState({...loginState, loading: true});
    const userData = {
      email: loginState.email,
      password: loginState.password
    };

    if (isMarketPlaceLogin || isCartLogin){
      if(cartOrderPayloadFromRedux && cartOrderPayloadFromRedux.runningmenu?.orders_attributes?.length > 0) {
        dispatch(loginUser?.(userData, () => {setLoginState({...loginState, loading: false})}, undefined, cartOrderPayloadFromRedux as PlaceCartOrderPayload))
      } else {
        dispatch(loginUser?.(userData, () => {setLoginState({...loginState, loading: false})}, loginSuccessHandler))
      }
    } else {
      dispatch(loginUser?.(userData, () => {setLoginState({...loginState, loading: false})}))
    }
  }

  const togglePasswordVisibility = () => {
    setLoginState({...loginState, isPasswordShown: !loginState.isPasswordShown })
  }

  // const openModal = () => setLoginState({...loginState, modalIsOpen: true});

  // const closeModal = () => setLoginState({...loginState, modalIsOpen: false});

  useEffect(() => {
    if (auth?.isAuthenticated) {
      // this.redirectUser();
      if (isMarketPlaceLogin === undefined && isCartLogin === undefined) {
        const freshStorageData: any = localStorage.getItem('apc_user_id')
        if (isEmpty(freshStorageData)) {
          window.location.reload()
        }else{
          redirectUser();
        }
      }
    }
    if (errors) {
      setLoginState({...loginState, errors: errors.errors});
    }
    setLoginState({...loginState, loading: false});

    // if (onRef) {
    //   onRef?.(openModal)
    // }
    // eslint-disable-next-line
  }, [auth?.isAuthenticated, errors]);


  const handleLoginSuccess = (response: any) => {
    const googleToken = response.credential
    localStorage?.removeItem?.('signup-in-progress');

    // setLoginState({ ...loginState, googleIdentificationToken: googleToken, googleSignupInProgress: true });

    if (googleToken) {
      const googleAuthenticatedUser = {
        google_token: googleToken
      }
      dispatch(loginUser?.(googleAuthenticatedUser, () => { 
        setLoginState({ ...loginState, loading: false, googleSignupInProgress: true }) 
        history.push('/user/signup')
  }))
    }
  };

  useEffect(() => {

    const localHeaders = JSON.parse(localStorage.getItem('headers') || '{}')
    if (loginState?.googleSignupInProgress && isEmpty(localHeaders)) {
      // history.push('/user/signup')

    } else {
      // history.push('/signin')
    }

    // eslint-disable-next-line
  }, [loginState.googleSignupInProgress])
  const { isPasswordShown, resetMessage, email, password, isChecked, loading } = loginState;

  return (
    <>
    {isMarketPlaceLogin ?
      <Modal
        isOpen={isOpenModal}
        // isOpen={isOpenSignup ?? false}
        style={customStyles}
        // onRequestClose={closeModal}
        onRequestClose={closeModal}
      >
        <div className='modal-dialog'>
          <div className='modal-content px-4 py-32' style={{ 'borderRadius': '10px' }}>
            {/* Modal Header */}
            <div className={`border-0 bg-white d-flex justify-content-between align-items-center`}>
              <span></span>
              <h4 id='modal-title' className='modal-title fooditem-text-capitalize text-case order-popup-title order-popup-header-title'>
                {i18n ? i18n.t('Log In') : 'Log In'}
              </h4>
              <button type='button' onClick={closeModal} className='close m-0 p-0 pr-2 modal-close ' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>
                  <i className="fas fa-times"></i>
                  </span>
                </button>
                {/* <div className="login-container">
                  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}`}>
                    <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      // onError={handleLoginError}
                      useOneTap
                    />
                  </GoogleOAuthProvider>
                </div> */}
              </div>
            {/* Modal Body */}
            <div
              // style={{ height: `calc(95vh - 290px)`, overflowX: 'hidden',overflowY: 'scroll' }}
              className='position-relative order-poup-header-wrapper '
              id='order-popup-body'
            >
              {errors.errors ?
                <div className='alert alert-danger login-alert' role='alert' >
                  {errors.errors}
                </div>
                : ''
              }
              <form className='form-signin px-2 w-100' onSubmit={onSubmit}>
                <label htmlFor='inputEmail' className='mb-1'> { i18n?.t('Email address')} </label>
                <input type='email' id='inputEmail' className='form-control rounded' name='email' value={email} onChange={handleChange} autoComplete='on' required autoFocus />

                <label className='mt-3 mb-1' htmlFor='inputPassword'> { i18n?.t('Password')}</label>
                <div className='position-relative'>
                  <input type={(isPasswordShown) ? 'text' : 'password'} id='inputPassword' className='form-control rounded' name='password' value={password} onChange={handleChange} autoComplete='current-password' required />
                  <i className={`password-icon fas fa-solid ${isPasswordShown ? 'fa-eye-slash' : 'fa-eye'} `}
                    onClick={togglePasswordVisibility}></i>
                </div>
                <div className='checkbox mb-3 mt-3 d-flex justify-content-between align-items-center'>
                  <label className='text-gray-primary m-0'>
                    <input type='checkbox' defaultValue='remember-me' name='isChecked' checked={isChecked} onChange={onChange} />&nbsp; { i18n?.t('Keep me logged in')}
                  </label>
                  <React.Fragment>
                    <Link className='text-danger d-block text-center fw-600' to='/forgot_password' > { i18n?.t('Forgot Password ?')}</Link>
                  </React.Fragment>
                </div>
                <button
                  type='submit'
                  className='btn btn-lg btn-danger btn-block'
                >
                  {loading ?
                    <ButtonSpinner /> :
                     i18n?.t('Log In')
                    }
                  </button>
                  <div className="login-container my-2">
                    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}`}>
                      <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        // onError={handleLoginError}
                        useOneTap
                        theme='filled_blue'
                        type='standard'
                        size='large'
                        text='continue_with'
                        width={400}
                        containerProps={{
                          className: 'custom-google-signin',
                        }}
                      />
                    </GoogleOAuthProvider>
                  </div>
                  {/* <p className='mt-5 mb-3 text-muted text-center'>
                { (i18n.t('© Copyright') + new Date().getFullYear() + i18n.t('Chowmill'))}
              </p> */}
                <p className='fs-18 fw-normal text-center text-gray-200 m-0 mt-3'>Not currently registered? <span className='text-danger cursor-pointer' onClick={() => { closeModal?.(); signupLoginRedirect?.('login') }}>Sign Up</span></p>
              </form>

            </div>
          </div>
        </div>
      </Modal>
    :
      <>
        {!isCartLogin ?
          <div className='container-fluid h-100 '>
            <div className='row h-100 justify-content-center'>
              <div className='col-12 col-md-4 text-center'>
                <div className='d-flex justify-content-center align-items-center mt-5'>
                  <img src='/imgs/chowmill_icon.svg' className='mr-3' alt='' style={{ height: 60 }} />
                  <h1 className='m-0 logo-chowmill-t' style={{ color: '#303030', fontSize: 40 }}>Chowmill</h1>
                </div>
                <h2 className='mt-5'>{i18n?.t('Login')}</h2>
                <h5 className='signup_subtext'>{i18n?.t('Not currently registered?')}&nbsp;
                  <Link to='/user/signup' >{i18n?.t('Signup')}</Link> </h5>
                {errors.errors ?
                  <div className='alert alert-danger login-alert' role='alert' >
                    {errors.errors}
                  </div>
                  : ''
                }
                {resetMessage && isEmpty(errors.errors) ?
                  <div className='alert alert-success login-alert' role='alert'>
                    {i18n?.t('Please login below using your Email and Password.')}
                  </div>
                  : ''}
                <form className='form-signin' onSubmit={onSubmit} style={styles}>
                  <label htmlFor='inputEmail'> {i18n?.t('Email address')} </label>
                  <input type='email' id='inputEmail' className='form-control' name='email' value={email} onChange={handleChange} autoComplete='on' required autoFocus />

                  <label className='mt-4' htmlFor='inputPassword'> {i18n?.t('Password')}</label>
                  <div className='position-relative'>
                    <input type={(isPasswordShown) ? 'text' : 'password'} id='inputPassword' className='form-control' name='password' value={password} onChange={handleChange} autoComplete='current-password' required />
                    <i className={`password-icon fas fa-solid ${isPasswordShown ? 'fa-eye-slash' : 'fa-eye'} `}
                      onClick={togglePasswordVisibility}></i>
                  </div>
                  <div className='checkbox mb-3 mt-3'>
                    <label>
                      <input type='checkbox' defaultValue='remember-me' name='isChecked' checked={isChecked} onChange={onChange} />&nbsp; { i18n?.t('Keep me logged in')}
                    </label>
                    <React.Fragment>
                      <Link className='text-danger d-block text-center' to='/forgot_password' > {i18n?.t('Forgot Password')}</Link>
                    </React.Fragment>
                  </div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-danger btn-block'
                  >
                    {loading ?
                        <ButtonSpinner /> :
                        i18n?.t('Sign in')
                      }
                    </button>
                    <div className="login-contain mt-3">
                      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}`}>
                        <GoogleLogin
                          onSuccess={handleLoginSuccess}
                          useOneTap
                          theme='filled_blue'
                          size='large'
                          text='continue_with'
                          width={rawWidth}
                          containerProps={{
                            className: 'custom-google',
                          }}
                        />
                      </GoogleOAuthProvider>
                    </div>
                    <p className='mt-5 mb-3 text-muted text-center'>
                      {i18n && (i18n?.t('© Copyright') + new Date().getFullYear() + i18n?.t('Chowmill'))}
                    </p>
                </form>
              </div>
              <div className='col-md-8 d-none d-lg-block login_banner' id='banner'>
              </div>
            </div>
          </div>
          :
          <div className='text-center'>
            {errors.errors ?
              <div className='alert alert-danger login-alert' role='alert' >
                {errors.errors}
              </div>
              : ''
            }
            {resetMessage && isEmpty(errors.errors) ?
              <div className='alert alert-success login-alert' role='alert'>
                { i18n?.t('Please login below using your Email and Password.')}
              </div>
              : ''}
            <form className='form-signin' onSubmit={onSubmit}>
              <label htmlFor='inputEmail'> { i18n?.t('Email address')} </label>
              <input type='email' id='inputEmail' className='form-control' name='email' value={email} onChange={handleChange} autoComplete='on' required autoFocus />

              <label className='mt-4' htmlFor='inputPassword'> { i18n?.t('Password')}</label>
              <div className='position-relative'>
                <input type={(isPasswordShown) ? 'text' : 'password'} id='inputPassword' className='form-control' name='password' value={password} onChange={handleChange} autoComplete='current-password' required />
                <i className={`password-icon fas fa-solid ${isPasswordShown ? 'fa-eye-slash' : 'fa-eye'} `}
                  onClick={togglePasswordVisibility}></i>
              </div>
              <div className='checkbox mb-3 mt-3 d-flex aling-items-center justify-content-between'>
                <label>
                  <input type='checkbox' defaultValue='remember-me' name='isChecked' checked={isChecked} onChange={onChange} />&nbsp; { i18n?.t('Keep me logged in')}
                </label>
                <React.Fragment>
                  <Link className='text-danger d-block text-center' to='/forgot_password' > { i18n?.t('Forgot Password')}</Link>
                </React.Fragment>
              </div>
              <button
                type='submit'
                className='btn btn-lg btn-danger btn-block'
              >
                {loading ?
                  <ButtonSpinner /> :
                  i18n?.t('Log In')
                }
                </button>
                <div className="login-container my-2">
                  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}`}>
                    <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      useOneTap
                      theme='filled_blue'
                      size='large'
                      text='continue_with'
                      width={360}
                      containerProps={{
                        className: 'custom-google-signin',
                      }}
                    />
                  </GoogleOAuthProvider>
                </div>
                <p className='fs-18 fw-normal text-center text-gray-200 m-0 mt-3'>Not currently registered? <span className='text-danger cursor-pointer' onClick={() => cartDisplayHandling?.('signUp')}>Sign Up</span></p>
              </form>
          </div>
        }
      </>
    }
    </>
  );
}

export default Login;