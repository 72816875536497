import React, { useState, useRef, FC } from 'react';
import GoogleMap from 'google-map-react';
import Modal from 'react-modal';
import { sendVendorInvite, setAllButtonsLoadingState } from '../../Actions/inviteActions';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '692px',
    padding: '0',
  }
};

type InviteVendorPopUpProps = {
  closeModal?: () => void;
  isOpenModal: boolean;
}
const InviteVendorPopUp: FC<InviteVendorPopUpProps> = ({closeModal, isOpenModal}) => {
  const { loggedInUserCompany: companyData } = getloggedInUser();
  const defaultLocation = companyData && { lat: companyData?.latitude, lng: companyData?.longitude }
  const markerRef: any = useRef([]);
  const [mapState, setMapState] = useState({ mapApiLoaded: false, mapInstance: null, mapApi: null });
  const [placeService, setPlaceService] = useState<any>()
  // const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loadedPlaces, setLoadedPlaces] = useState<any>('');
  const dispatch: any = useDispatch();
  const loadingState = useSelector((state: ReduxRootState) => state?.invite_status?.inviteVendorButtonsLoading);

  // useEffect(() => {
  //   onRef(openModal);
  //   return () => {
  //     onRef(null);
  //   }
  // }, [onRef])
  // const openModal = () => {
  //   setModalIsOpen(true)
  // }
  // const closeModal = () => {
  //   setModalIsOpen(false);
  //   setLoadedPlaces('');
  // }
  const searchPlaceHandler = (event: any) => {
    if (event.key && event.key !== 'Enter') return;
    const searchInput: any = document.getElementById('search_place-input');
    const value = searchInput ? searchInput.value : '';
    dispatch(setAllButtonsLoadingState());
    removeMarkers();
    if (value === '') {
      setLoadedPlaces('')
      return
    }
    checkplaces(value)
  }
  const checkplaces = (value: any) => {
    placeService.nearbySearch({
      query: value,
      keyword: value,
      location: defaultLocation,
      radius: '48280.3',
      types: ['restaurant', 'food']
    }, (results: any, status: any) => {
      if (status !== 'OK') {
        setLoadedPlaces([])
        return
      };
      createMarkers(results);
      setLoadedPlaces(results)
    });
  }
  const createMarkers = (places: any) => {
    const maps: any = mapState.mapApi;
    const map: any = mapState.mapInstance;
    if (!maps || !map) {
      return;
    }
    const bounds = new maps.LatLngBounds();
    for (let place of places) {
      const marker = new maps.marker.AdvancedMarkerElement({
        position: place.geometry.location,
        map: map
      });
      bounds.extend(place.geometry.location);
      markerRef.current.push(marker);
      marker.setMap(map)
    }
    map.fitBounds(bounds);
  }

  
  // const createMarkers = (places: any) => {
  //   const maps: any = mapState.mapApi;
  //   const map: any = mapState.mapInstance;
  //   if (!maps || !map) {
  //     return;
  //   }
  //   const bounds = new maps.LatLngBounds();
  //   for (let place of places) {
  //     const marker: any = new maps.Marker({
  //       position: place.geometry.location,
  //       map: map
  //     });
  //     bounds.extend(place.geometry.location);
  //     markerRef.current.push(marker);
  //     marker.setMap(map)
  //   }
  //   map.fitBounds(bounds);
  // }
  const removeMarkers = () => {
    for (let marker of markerRef.current) {
      marker.setMap(null);
    }
    markerRef.current.splice(0, markerRef.current.length)
  }
  const apiHasLoaded = (googleMapAPI: { map: any, maps: any, ref: Element | null }) => {
    const { map, maps } = googleMapAPI;
    if (!maps || !map) {
      return;
    }
    map.setOptions({ mapTypeControl: true, streetViewControl: true, mapId: 'DEMO_MAP_ID' })
    setMapState({ mapApiLoaded: true, mapInstance: map, mapApi: maps })
    const palceServiceInstance = new maps.places.PlacesService(map);
    setPlaceService(palceServiceInstance);
  }
  const sendVendorInviteHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, placeData: any, index: number) => {
    const btnID = 'Btn-' + index;
    if (loadingState[btnID]) {
      return;
    }
    event.preventDefault();
    const data = { vendor_name: placeData?.name as string, vendor_address: placeData?.vicinity as string }
    dispatch(sendVendorInvite(data, btnID));
  }
  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Example Modal'>
      <div className='modal-header' style={{ width: '690px' }}>
        <h5 className='modal-title' id='modal-title'>Find & Invite Vendors </h5>
        <button type='button' onClick={closeModal} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
      </div>
      <div id='mydiv' className='modal-body' style={{ width: '100%' }}>
        <div>
          <div>
            <div id='main' className='container'>
              <div className='form-row'>
                <div className='col-11 m-auto text-center'>
                  <p className='text-muted'>Grow your company's marketplace by inviting your current vendors to join Chowmill.</p>
                  <p className='text-muted'><span className='text-danger'>Your vendor not on Google?</span><br />No problem! Just email us their name and contact information at <a href='mailto:vendors@chowmill.com'> vendors@chowmill.com</a>
                  </p>
                </div>
              </div>
              <form onSubmit={e => e.preventDefault()} className='text-left'>
                <div>
                  <div className='form-row'>
                    <div className='col-sm-12 company-required-field p-3' style={{ padding: '250px !important' }}>
                      {mapState.mapApiLoaded && <React.Fragment>
                        <h6 className='orderform-section-name'>Vendor Name or Cuisine</h6>
                        <div className='d-flex align-items-center py-1'>
                          <input type='text' id='search_place-input' className='form-control' name='place' onKeyPress={searchPlaceHandler} />
                          <i className='fas fa-search pl-2 pr-1 search-vendor-name' style={{ color: 'gray', cursor: 'pointer' }} onClick={searchPlaceHandler}></i>
                        </div></React.Fragment>}
                      <div id='map' className='main-google-map'>
                        <GoogleMap
                          defaultZoom={14}
                          defaultCenter={defaultLocation}
                          bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '',
                            libraries: ['places', 'geometry', 'marker'],
                          }}
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={apiHasLoaded}
                        />
                        {loadedPlaces && loadedPlaces.length <= 0 &&
                          <div className='no-place-found position-absolute w-100 h-100 d-flex justify-content-center align-items-center'>
                            <h5>No Place Found</h5>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {loadedPlaces && loadedPlaces.map((place: any, index: any) => {
                    return <div className='form-row mt-3 ' key={index}>
                      <div className='col-sm-9 company-required-field p-3'><h5>{place.name}</h5>
                        <h6 className='muted'>{place?.vicinity}</h6>
                      </div>
                      <div className='col-sm-3 company-required-field p-3 text-center d-flex align-items-start justify-content-center'>
                        {loadingState['Btn-' + index] === false ? <i style={{ color: '#28a745' }} className='far fa-check-circle fa-2x'></i> :
                          <button className='invite-vendor btn btn-block btn-success' type='button' style={{ opacity: 1 }} onClick={(e) => sendVendorInviteHandler(e, place, index)}>
                            {loadingState['Btn-' + index] === undefined ? 'Invite' :
                              <div className='spinner-border spinner-border-sm' role='status'>
                                <span className='sr-only'></span>
                              </div>
                            }
                          </button>
                        }
                      </div>
                    </div>
                  })}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default InviteVendorPopUp;